// const initialState = {
//     isLoggedIn: false,
//     user: null,
//     error: null,
//     loading: false, 
//   };
  
//   const authReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case 'AUTH_REQUESTED':
//         return { ...state, loading: true, error: null };
  
//       case 'AUTH_SUCCESS':
//         return {
//           ...state,
//           isLoggedIn: true,
//           user: action.payload.user,
//           loading: false,
//           error: null,
//         };
  
//       case 'AUTH_FAILED':
//         return { ...state, loading: false, error: action.payload };
  
//         case 'LOGOUT_REQUESTED':
//           return { ...state, loading: true, error: null };
    
//         case 'LOGOUT_SUCCESS':
//           //console.log('authReducer handling LOGOUT_SUCCESS, resetting to initialState');
//           return initialState; 
          
//         case 'LOGOUT_FAILED':
//           return { ...state, loading: false, error: action.payload };
  
//       default:
//         return state;
//     }
//   };
  
//   export default authReducer;
  

import { createSlice } from '@reduxjs/toolkit';
//import Cookies from 'js-cookie';

const initialState = {
  isLoggedIn: false, // Removed Cookies.get('accessToken')
  //isLoggedIn: !!Cookies.get('accessToken'), // Check cookie presence
  user: null,
  error: null,
  loading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authRequested(state) {
      state.loading = true;
      state.error = null;
    },
    authSuccess(state, action) {
      console.log('Reducer: AUTH_SUCCESS, payload:', action.payload);
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.loading = false;
      state.error = null;
    },
    authFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    logoutRequested(state) {
      state.loading = true;
      state.error = null;
    },
    logoutSuccess(state) {
      // Reset to initial state without localStorage
      return initialState;
    },
    logoutFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  authRequested,
  authSuccess,
  authFailed,
  logoutRequested,
  logoutSuccess,
  logoutFailed,
} = authSlice.actions;

export default authSlice.reducer;