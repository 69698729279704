import { call, put, takeLatest, select } from 'redux-saga/effects';
import axiosInstance from '../../api/axios';
import {
  TWILIO_LOOKUP_REQUEST,
  twilioLookupSuccess,
  twilioLookupFailure,
  FETCH_AGENTS_REQUEST,
  fetchAgentsSuccess,
  fetchAgentsFailure,
  FETCH_RETELL_AGENT_REQUEST,
  fetchRetellAgentSuccess,
  fetchRetellAgentFailure,
  TWILIO_PURCHASE_REQUEST,
  twilioPurchaseSuccess,
  twilioPurchaseFailure,
  TWILIO_ASSOCIATE_REQUEST,
  twilioAssociateSuccess,
  twilioAssociateFailure,
  TWILIO_IMPORT_REQUEST,
  twilioImportSuccess,
  twilioImportFailure,
  CREATE_RETELL_LLM_REQUEST,
  createRetellLLMSuccess,
  createRetellLLMFailure,
  FETCH_LLM_REQUEST,
  fetchLLMSuccess,
  fetchLLMFailure,
  UPDATE_LLM_REQUEST,
  updateLLMSuccess,
  updateLLMFailure,
  CREATE_RETELL_AGENT_REQUEST,
  createRetellAgentSuccess,
  createRetellAgentFailure,
  ASSIGN_PHONE_NUMBER_REQUEST,
  assignPhoneNumberSuccess,
  assignPhoneNumberFailure,
  CREATE_LLM_COMPLETE,
  createLLMComplete,
  INVOKE_BEDROCK_AGENT_REQUEST,
  invokeBedrockAgentSuccess,
  invokeBedrockAgentFailure,
} from '../actions/twilioActions';

const getApiBaseUrl = () => process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
// Replace with your API Gateway endpoint URL:
const API_URL = 'https://tumhm0tn4b.execute-api.us-east-2.amazonaws.com/dev-procure-number';

//const RETELL_API_URL = 'https://api.retellai.com/import-phone-number';

const RETELL_API_LLM = 'https://api.retellai.com/create-retell-llm';

const RETELL_API_KEY = process.env.RETELL_API_KEY;

const sipTrunk = process.env.SIP_TRUNK;

function* handleTwilioLookup(action) {
  try {
    const { areaCode, friendlyName } = action.payload;
    const payload = { action: 'lookup', areaCode, friendlyName };
    const response = yield call(fetch, API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    const data = yield response.json();
    if (response.ok && data.success) {
      yield put(twilioLookupSuccess(data.numbers));
    } else {
      yield put(twilioLookupFailure(data.message || 'Lookup failed'));
    }
  } catch (error) {
    yield put(twilioLookupFailure(error.message));
  }
};

function* handleFetchAgents() {
  try {
    const response = yield call(axiosInstance.get, `${getApiBaseUrl()}/api/agents`);
    yield put(fetchAgentsSuccess(response.data));
  } catch (error) {
    yield put(fetchAgentsFailure(error.message));
  }
};

function* handleFetchRetellAgent(action) {
  try {
    const { agent_id } = action.payload;
    const response = yield call(fetch, `https://api.retellai.com/get-agent/${agent_id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${RETELL_API_KEY}`,
        'Content-Type': 'application/json',
      },
    });
    const data = yield response.json();
    if (!response.ok) throw new Error(data.error || 'Failed to fetch agent');
    yield put(fetchRetellAgentSuccess(data));
  } catch (error) {
    yield put(fetchRetellAgentFailure(error.message));
  }
}

function* handleTwilioPurchase(action) {
  try {
    const { selectedNumber, friendlyName } = action.payload;
    const payload = { action: 'purchase', selectedNumber, friendlyName };
    const response = yield call(fetch, API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    const data = yield response.json();
    if (response.ok && data.success) {
      yield put(twilioPurchaseSuccess({
        purchasedNumber: data.purchasedNumber,
        phoneNumberSid: data.phoneNumberSid, // Include phoneNumberSid
      }));
    } else {
      yield put(twilioPurchaseFailure(data.message || 'Purchase failed'));
    }
  } catch (error) {
    yield put(twilioPurchaseFailure(error.message));
  }
}

function* handleTwilioAssociate(action) {
  try {
    const { selectedNumber, phoneNumberSid } = action.payload; // Extract phoneNumberSid
    const payload = { action: 'associate', selectedNumber, phoneNumberSid }; // Include phoneNumberSid
    const response = yield call(fetch, API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    const data = yield response.json();
    if (response.ok && data.success) {
      yield put(twilioAssociateSuccess({
        ...data.associationDetails,
        terminationUri: `${sipTrunk}` // Replace with your SIP trunk termination URI
      }));
    } else {
      yield put(twilioAssociateFailure(data.message || 'Association failed'));
    }
  } catch (error) {
    yield put(twilioAssociateFailure(error.message));
  }
}

function* handleTwilioImport(action) {
  try {
    const { phoneNumber, terminationUri } = action.payload;

    // Log the parameters for debugging
    // console.log("Phone Number:", phoneNumber);
    // console.log("Termination URI:", terminationUri);

    // Call the Lambda to handle the import
    const response = yield call(fetch, API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        action: 'import',
        selectedNumber: phoneNumber,
        terminationUri: terminationUri,
      }),
    });

    const data = yield response.json();

    if (response.ok && data.success) {
      yield put(twilioImportSuccess(data.importDetails));
    } else {
      yield put(twilioImportFailure(data.message || 'Import failed'));
    }
  } catch (error) {
    yield put(twilioImportFailure(error.message));
  }
}

// function* handleCreateRetellLLM(action) {
//   try {
//     const { model, general_prompt, general_tools, importedNumber } = action.payload;
//     const payload = {
//       action: 'create-retell-llm', 
//       model,
//       general_prompt,
//       general_tools,
//       importedNumber,
//     };
//     console.log("Sending to Lambda:", JSON.stringify(payload, null, 2)); 

//     const response = yield call(fetch, API_URL, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(payload),
//     });

//     const data = yield response.json();
//     console.log("Lambda Response:", JSON.stringify(data, null, 2)); // Optional: for debugging

//     if (response.ok) {
//       yield put(createRetellLLMSuccess(data));
//     } else {
//       yield put(createRetellLLMFailure(data.error || 'Failed to create LLM'));
//     }
//   } catch (error) {
//     yield put(createRetellLLMFailure(error.message));
//   }
// };

function* handleCreateRetellLLM(action) {
  try {
    const { model, general_prompt, general_tools, importedNumber, company_name, agent_name, job_site, intro, positions, questions, disclaimer } = action.payload;
    const payload = {
      action: 'create-retell-llm',
      model,
      general_prompt,
      general_tools,
      importedNumber,
    };
    //console.log("Sending to Lambda:", JSON.stringify(payload, null, 2));
    const response = yield call(fetch, API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    const data = yield response.json();
    //console.log("Lambda Response:", JSON.stringify(data, null, 2));
    if (!response.ok) throw new Error(data.error || 'Failed to create LLM');
    if (!data.llmDetails || !data.llmDetails.llm_id) {
      console.error('Lambda response missing llm_id:', data);
      throw new Error('LLM creation response missing llm_id');
    }

    // Database insertion
    //console.log(`Saving LLM to DB: llm_id=${data.llmDetails.llm_id}`);
    yield call(axiosInstance.post, `${getApiBaseUrl()}/api/llms`, {
      llm_id: data.llmDetails.llm_id,
      user_id: yield select(state => state.auth.user.id),
      company_name,
      agent_name,
      job_site,
      intro,
      positions,
      questions,
      disclaimer,
    });

    yield put(createRetellLLMSuccess(data.llmDetails)); // Fix: Pass only llmDetails
    yield put(createLLMComplete(data.llmDetails, agent_name, questions));
  } catch (error) {
    console.error('Error creating LLM:', error.message);
    yield put(createRetellLLMFailure(error.message));
  }
}

function* handleFetchLLM(action) {
  try {
    const { llm_id } = action.payload;
    const response = yield call(axiosInstance.get, `${getApiBaseUrl()}/api/llms/${llm_id}`);
    yield put(fetchLLMSuccess(response.data));
  } catch (error) {
    yield put(fetchLLMFailure(error.message));
  }
}

function* handleUpdateLLM(action) {
  try {
    const { llm_id, company_name, agent_name, job_site, intro, positions, questions, disclaimer, model, general_prompt, general_tools } = action.payload;
    //console.log('Updating LLM in DB:', { llm_id, company_name, agent_name, job_site, intro, positions, questions, disclaimer });
    const dbResponse = yield call(axiosInstance.patch, `${getApiBaseUrl()}/api/llms/${llm_id}`, {
      company_name, agent_name, job_site, intro, positions, questions, disclaimer,
    });
    //console.log('DB Update Response:', dbResponse.data);

    //console.log('Updating Retell LLM:', { action: 'update-retell-llm', llm_id, model, general_prompt, general_tools });
    const response = yield call(fetch, API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        action: 'update-retell-llm',
        llm_id,
        model,
        general_prompt,
        general_tools,
      }),
    });
    const data = yield response.json();
   // console.log('Lambda Response:', data);
    if (!response.ok) throw new Error(data.error || 'Failed to update LLM');
    yield put(updateLLMSuccess(data));
  } catch (error) {
    console.error('Update LLM error:', error.message, error.response?.data);
    yield put(updateLLMFailure(error.message));
  }
}

// function* handleCreateRetellAgent(action) {
//   try {
//     const { llm_id, importedNumber, agent_name, language, gender, questions } = action.payload;
//     if (!llm_id) {
//       console.error('llm_id missing in action.payload:', action.payload);
//       throw new Error('llm_id is required for agent creation');
//     }
//     const payload = {
//       action: 'create-agent',
//       llm_id,
//       importedNumber,
//       agent_name,
//       language,
//       gender,
//       questions,
//     };
//     console.log("Sending to Lambda (Agent):", JSON.stringify(payload, null, 2));

//     const response = yield call(fetch, API_URL, {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(payload),
//     });

//     const data = yield response.json();
//     console.log("Lambda Response (Agent):", JSON.stringify(data, null, 2));

//     if (response.ok) {
//       yield put(createRetellAgentSuccess(data));
//     } else {
//       yield put(createRetellAgentFailure(data.error || 'Failed to create agent'));
//     }
//   } catch (error) {
//     yield put(createRetellAgentFailure(error.message));
//   }
// }

function* handleCreateRetellAgent(action) {
  try {
    const payload = { ...action.payload, action: 'create-agent' };
    //console.log("Sending to Lambda (Agent):", JSON.stringify(payload, null, 2));
    const response = yield call(fetch, API_URL, { method: 'POST', body: JSON.stringify(payload), headers: { 'Content-Type': 'application/json' } });
    const data = yield response.json();
    //console.log("Lambda Response (Agent):", JSON.stringify(data, null, 2));
    if (response.ok) {
      yield put(createRetellAgentSuccess(data));
    } else {
      yield put(createRetellAgentFailure(data.error || 'Failed to create agent'));
    }
  } catch (error) {
    console.error('Agent creation error:', error.message);
    yield put(createRetellAgentFailure(error.message));
  }
}
function* handleAssignPhoneNumber(action) {
  const { phoneNumber, agentId, llmId, userId, alias, service } = action.payload;
  //console.log("Assign Phone Number Payload to Backend from saga saga saga saga:", { agentId, llmId, userId, alias, service });
  const payload = { action: 'assign-phone-number', phoneNumber, agentId, llmId, userId, alias, service };
  //console.log("Sending to Lambda (Assign Number):", JSON.stringify(payload, null, 2));
  try {
    const response = yield call(fetch, API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    const data = yield response.json();
    if (response.ok && data.success) {
      // Call backend API to insert into agents table
      const { auth } = yield select(state => state);
      const company_name = auth.user?.company_name || 'Unknown';
      yield call(axiosInstance.post, `${getApiBaseUrl()}/api/agents`, {
        agent_id: agentId,
        llm_id: llmId,
        user_id: userId || auth.user?.id,
        alias,
        service,
        company_name,
      });
      yield put(assignPhoneNumberSuccess(data));
    } else {
      yield put(assignPhoneNumberFailure(data.error || 'Failed to assign phone number'));
    }
  } catch (error) {
    yield put(assignPhoneNumberFailure(error.message));
  }
}

// function* handleInvokeBedrockAgent(action) {
//   try {
//     const { agentId, message, userId } = action.payload;
//     console.log('Saga received:', { agentId, message, userId });
//     const response = yield call(fetch, 'https://tumhm0tn4b.execute-api.us-east-2.amazonaws.com/dev-procure-number', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ action: 'invoke-bedrock-agent', agentId, message, userId }),
//     });
//     const data = yield response.json();
//     console.log('Lambda response:', data);
//     if (response.ok) {
//       yield put(invokeBedrockAgentSuccess(data));
//     } else {
//       console.error('Lambda error:', data.error);
//       yield put(invokeBedrockAgentFailure(data.error));
//     }
//   } catch (error) {
//     console.error('Saga fetch error:', error.message);
//     yield put(invokeBedrockAgentFailure(error.message));
//   }
// }
function* handleInvokeBedrockAgent(action) {
  try {
    const { agentId, message, userId } = action.payload;
    console.log('Saga received:', { agentId, message, userId });
    const response = yield call(
      axiosInstance.post,
      `${getApiBaseUrl()}/api/bots/chat`, 
      { agentId, message, userId }
    );
    console.log('Backend response:', response.data);
    yield put(invokeBedrockAgentSuccess(response.data));
  } catch (error) {
    console.error('Saga error:', error.response?.data || error.message);
    yield put(invokeBedrockAgentFailure(error.response?.data?.error || error.message));
  }
}

export function* watchTwilio() {
  yield takeLatest(TWILIO_LOOKUP_REQUEST, handleTwilioLookup);
  yield takeLatest(FETCH_AGENTS_REQUEST, handleFetchAgents);
  yield takeLatest(FETCH_RETELL_AGENT_REQUEST, handleFetchRetellAgent);
  yield takeLatest(TWILIO_PURCHASE_REQUEST, handleTwilioPurchase);
  yield takeLatest(TWILIO_ASSOCIATE_REQUEST, handleTwilioAssociate);
  yield takeLatest(TWILIO_IMPORT_REQUEST, handleTwilioImport);
  yield takeLatest(CREATE_RETELL_LLM_REQUEST, handleCreateRetellLLM);
  yield takeLatest(CREATE_RETELL_AGENT_REQUEST, handleCreateRetellAgent);
  yield takeLatest(ASSIGN_PHONE_NUMBER_REQUEST, handleAssignPhoneNumber);
  yield takeLatest(FETCH_LLM_REQUEST, handleFetchLLM);
  yield takeLatest(UPDATE_LLM_REQUEST, handleUpdateLLM);
  yield takeLatest(CREATE_RETELL_LLM_REQUEST, handleCreateRetellLLM);
  yield takeLatest(INVOKE_BEDROCK_AGENT_REQUEST, handleInvokeBedrockAgent);
}