import {
  TWILIO_LOOKUP_REQUEST,
  TWILIO_LOOKUP_SUCCESS,
  TWILIO_LOOKUP_FAILURE,
  TWILIO_PURCHASE_REQUEST,
  TWILIO_PURCHASE_SUCCESS,
  TWILIO_PURCHASE_FAILURE,
  TWILIO_ASSOCIATE_REQUEST,
  TWILIO_ASSOCIATE_SUCCESS,
  TWILIO_ASSOCIATE_FAILURE,
  TWILIO_IMPORT_REQUEST,
  TWILIO_IMPORT_SUCCESS,
  TWILIO_IMPORT_FAILURE,
  CREATE_RETELL_LLM_REQUEST,
  CREATE_RETELL_LLM_SUCCESS,
  CREATE_RETELL_LLM_FAILURE,
  CREATE_RETELL_AGENT_REQUEST,
  CREATE_RETELL_AGENT_SUCCESS,
  CREATE_RETELL_AGENT_FAILURE,
  ASSIGN_PHONE_NUMBER_REQUEST,
  ASSIGN_PHONE_NUMBER_SUCCESS,
  ASSIGN_PHONE_NUMBER_FAILURE,
  FETCH_AGENTS_REQUEST,
  FETCH_AGENTS_SUCCESS,
  FETCH_AGENTS_FAILURE,
  FETCH_RETELL_AGENT_REQUEST,
  FETCH_RETELL_AGENT_SUCCESS,
  FETCH_RETELL_AGENT_FAILURE,
  FETCH_LLM_REQUEST,
  FETCH_LLM_SUCCESS,
  FETCH_LLM_FAILURE,
  UPDATE_LLM_REQUEST,
  UPDATE_LLM_SUCCESS,
  UPDATE_LLM_FAILURE,
  CREATE_LLM_COMPLETE,
  RESET_AGENT_CREATION,
  INVOKE_BEDROCK_AGENT_REQUEST,
  INVOKE_BEDROCK_AGENT_SUCCESS,
  INVOKE_BEDROCK_AGENT_FAILURE,
} from '../actions/twilioActions';

const initialState = {
  lookup: {
    loading: false,
    numbers: null,
    error: null,
  },
  purchase: {
    loading: false,
    purchasedNumber: null,
    phoneNumberSid: null, 
    error: null,
  },
  associate: {
    loading: false,
    associationDetails: null,
    error: null,
  },
  import: {
    loading: false,
    importedNumber: null,
    error: null,
  },
  llmCreation: {
    loading: false,
    llmDetails: null,
    error: null,
  },
  agentCreation: { 
    loading: false, 
    agentDetails: null, 
    error: null 
  },
  assignNumber: {
    loading: false,
    assignmentDetails: null,
    error: null,
  },
  agents: { 
    loading: false, 
    list: [], 
    error: null 
  },
  llmEdit: { 
    loading: false, 
    llmData: null, 
    error: null 
  },
  bedrockAgent: { 
    loading: false, 
    result: null, 
    error: null 
  },
};



const twilioReducer = (state = initialState, action) => {
  switch (action.type) {
    // Lookup
    case TWILIO_LOOKUP_REQUEST:
      return {
        ...state,
        lookup: { loading: true, numbers: null, error: null },
        purchase: initialState.purchase,
        associate: initialState.associate,
      };
    case FETCH_AGENTS_REQUEST:
      return { 
        ...state, 
        agents: { loading: true, list: [], error: null } 
      };
    case FETCH_AGENTS_SUCCESS:
      return { 
        ...state, 
        agents: { loading: false, list: action.payload, error: null } 
      };
    case FETCH_AGENTS_FAILURE:
      return { 
        ...state, 
        agents: { loading: false, list: [], error: action.payload } 
      };

    case FETCH_RETELL_AGENT_REQUEST:
      return { 
        ...state, 
        agents: { ...state.agents, loading: true, error: null } 
      };
    case FETCH_RETELL_AGENT_SUCCESS:
      return {
        ...state,
        agents: {
            loading: false,
            list: state.agents.list.map(agent =>
              agent.agent_id === action.payload.agent_id ? { ...agent, retellData: action.payload } : agent
            ),
            error: null,
          },
        };
    case FETCH_RETELL_AGENT_FAILURE:
      return { 
        ...state, 
        agents: { ...state.agents, loading:      false, error: action.payload } 
      };  
    case TWILIO_LOOKUP_SUCCESS:
      return {
        ...state,
        lookup: { loading: false, numbers: action.payload, error: null },
      };
    case TWILIO_LOOKUP_FAILURE:
      return {
        ...state,
        lookup: { loading: false, numbers: null, error: action.payload },
      };

    // Purchase
    case TWILIO_PURCHASE_REQUEST:
      return {
        ...state,
        purchase: { loading: true, purchasedNumber: null, phoneNumberSid: null, error: null },
      };
    case TWILIO_PURCHASE_SUCCESS:
      return {
        ...state,
        purchase: {
          loading: false,
          purchasedNumber: action.payload.purchasedNumber,
          phoneNumberSid: action.payload.phoneNumberSid, 
          error: null,
        },
      };
    case TWILIO_PURCHASE_FAILURE:
      return {
        ...state,
        purchase: { loading: false, purchasedNumber: null, phoneNumberSid: null, error: action.payload },
      };

    // Associate
    case TWILIO_ASSOCIATE_REQUEST:
      return {
        ...state,
        associate: { loading: true, associationDetails: null, error: null },
      };
    case TWILIO_ASSOCIATE_SUCCESS:
      return {
        ...state,
        associate: { loading: false, associationDetails: action.payload, error: null },
      };
    case TWILIO_ASSOCIATE_FAILURE:
      return {
        ...state,
        associate: { loading: false, associationDetails: null, error: action.payload },
      };

      case TWILIO_IMPORT_REQUEST:
      return {
        ...state,
        import: { loading: true, importedNumber: null, error: null },
      };
    case TWILIO_IMPORT_SUCCESS:
      return {
        ...state,
        import: { loading: false, importedNumber: action.payload, error: null },
      };
    case TWILIO_IMPORT_FAILURE:
      return {
        ...state,
        import: { loading: false, importedNumber: null, error: action.payload },
      };
      case CREATE_RETELL_LLM_REQUEST:
      return {
        ...state,
        llmCreation: { loading: true, llmDetails: null, error: null },
      };
    case CREATE_RETELL_LLM_SUCCESS:
      return {
        ...state,
        llmCreation: { loading: false, llmDetails: action.payload, error: null },
      };
    case CREATE_RETELL_LLM_FAILURE:
      return {
        ...state,
        llmCreation: { loading: false, llmDetails: null, error: action.payload },
      };
    case FETCH_LLM_REQUEST:
      return { 
          ...state, 
          llmEdit: { loading: true, llmData: null, error: null } 
        };
    case FETCH_LLM_SUCCESS:
      return { 
          ...state, 
          llmEdit: { loading: false, llmData: action.payload, error: null } 
        };
    case FETCH_LLM_FAILURE:
      return { 
          ...state, 
          llmEdit: { loading: false, llmData: null, error: action.payload } 
        };  
    case CREATE_RETELL_AGENT_REQUEST:
      return { 
          ...state, 
          agentCreation: { loading: true, agentDetails: null, error: null } };
    case CREATE_RETELL_AGENT_SUCCESS:
      return { 
          ...state, 
          agentCreation: { loading: false, agentDetails: action.payload, error: null } };
    case CREATE_RETELL_AGENT_FAILURE:
      return { 
          ...state, 
          agentCreation: { loading: false, agentDetails: null, error: action.payload } }; 
          
    case ASSIGN_PHONE_NUMBER_REQUEST:
        return { 
          ...state, 
          assignNumber: { loading: true, assignmentDetails: null, error: null } };
    case ASSIGN_PHONE_NUMBER_SUCCESS:
        return { 
          ...state, 
          assignNumber: { loading: false, assignmentDetails: action.payload, error: null } 
        };
    case ASSIGN_PHONE_NUMBER_FAILURE:
        return { 
          ...state, 
          assignNumber: { loading: false, assignmentDetails: null, error: action.payload } 
        };
    case UPDATE_LLM_REQUEST:
        return { 
          ...state, 
          llmEdit: { ...state.llmEdit, loading: true, error: null } 
      };
    case UPDATE_LLM_SUCCESS:
        return { 
          ...state, 
          llmEdit: { loading: false, llmData: action.payload, error: null } 
      };
    case UPDATE_LLM_FAILURE:
      return { 
          ...state, 
          llmEdit: { loading: false, llmData: null, error: action.payload } 
        };
    case CREATE_LLM_COMPLETE:
      return {
        ...state,
        llmCreation: {
          ...state.llmCreation,
          completed: true,
          agentName: action.payload.agentName,
          questions: action.payload.questions,
          },
        };

    case RESET_AGENT_CREATION:
      return {
        ...state,
        agentCreation: { loading: false, agentDetails: null, error: null },
  };
    case INVOKE_BEDROCK_AGENT_REQUEST:
      return { 
        ...state, 
        bedrockAgent: { loading: true, result: null, error: null } 
      };
    case INVOKE_BEDROCK_AGENT_SUCCESS:
      return { 
        ...state, 
        bedrockAgent: { loading: false, result: action.payload, error: null } 
      };
    case INVOKE_BEDROCK_AGENT_FAILURE:
      return { 
        ...state, 
        bedrockAgent: { loading: false, result: null, error: action.payload } 
      };

    default:
      return state;
  }
};

export default twilioReducer;
