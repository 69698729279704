"use client";

import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FileText, Waves, Languages, HandshakeIcon } from "lucide-react";

export function FloatingPaper({ count = 5 }) {
  const [dimensions, setDimensions] = useState({ width: 1200, height: 800 });

  useEffect(() => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Updated icons with full-strength colors (no /50 opacity)
  const icons = [
    { Icon: FileText, color: "icon-purple" }, // #c084fc
    { Icon: Waves, color: "icon-blue" }, // #60a5fa
    { Icon: Languages, color: "icon-green" }, // #4ade80
    { Icon: HandshakeIcon, color: "icon-amber" }, // #fbbf24
  ];

  return (
    <div className="floating-paper">
      {Array.from({ length: count }).map((_, i) => {
        const { Icon, color } = icons[Math.floor(Math.random() * icons.length)];

        return (
          <motion.div
            key={i}
            className="floating-item"
            initial={{
              x: Math.random() * dimensions.width,
              y: Math.random() * dimensions.height,
            }}
            animate={{
              x: [Math.random() * dimensions.width, Math.random() * dimensions.width, Math.random() * dimensions.width],
              y: [
                Math.random() * dimensions.height,
                Math.random() * dimensions.height,
                Math.random() * dimensions.height,
              ],
              rotate: [0, 180, 360],
            }}
            transition={{
              duration: 20 + Math.random() * 10,
              repeat: Number.POSITIVE_INFINITY,
              ease: "linear",
            }}
          >
            <div className="floating-card">
              <Icon className={`w-8 h-8 ${color}`} />
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}