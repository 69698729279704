// import { call, put, takeEvery } from 'redux-saga/effects';
// import { fetchInboundCallsSuccess, fetchInboundCallsFailure, fetchSignedUrlSuccess, fetchSignedUrlFailure, fetchPdfSignedUrlSuccess,
//   fetchPdfSignedUrlFailure } from '../actions/inboundCallsActions';

// function* fetchInboundCallsSaga() {
//   try {
//     const token = localStorage.getItem('token');
//     const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
//     const response = yield call(fetch, `${fetchUrl}/api/calls/inbound-calls-company-user`, {
//       method: 'GET',
//       headers: {
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       }
//     });

//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }

//     const data = yield response.json();
//     yield put(fetchInboundCallsSuccess(data));
//   } catch (error) {
//     console.error('Error fetching inbound calls:', error);
//     yield put(fetchInboundCallsFailure(error.message));
//   }
// }

// // function* fetchSignedUrlSaga(action) {
// //     const { call_id } = action.payload;
// //     try {
// //       const token = localStorage.getItem('token');
// //       const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
// //       const response = yield call(fetch, `${fetchUrl}/api/audios/generate-signed-url/${encodeURIComponent(call_id)}`, {
// //         headers: {
// //           'Authorization': `Bearer ${token}`,
// //           'Content-Type': 'application/json'
// //         }
// //       });
  
// //       if (!response.ok) {
// //         throw new Error('Failed to fetch signed URL');
// //       }
  
// //       const data = yield response.json();
// //       yield put(fetchSignedUrlSuccess(data.url));
// //     } catch (error) {
// //       console.error('Error fetching signed URL:', error);
// //       yield put(fetchSignedUrlFailure(error.message));
// //     }
// //   }

// function* fetchSignedUrlSaga(action) {
//   const { call_id } = action.payload;
//   try {
//     const token = localStorage.getItem('token');
//     const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';

//     // console.log('[Saga] Attempting to fetch audio stream from:', `${fetchUrl}/api/audios/audio-stream/${encodeURIComponent(call_id)}`);

//     // 1) Call the new streaming route
//     const response = yield call(fetch, `${fetchUrl}/api/audios/audio-stream/${encodeURIComponent(call_id)}`, {
//       method: 'GET',
//       headers: {
//         'Authorization': `Bearer ${token}`,
//         // no need for "Content-Type: application/json" because we expect binary data
//       }
//     });

//     if (!response.ok) {
//       throw new Error(`Failed to fetch audio stream. Status: ${response.status}`);
//     }
//     //console.log('[Saga] Converting response into a blob...');

//     // 2) Convert the streaming response into a Blob
//     const blob = yield call([response, response.blob]);
//     //console.log('[Saga] Blob details -> size:', blob.size, 'type:', blob.type);

//     // 3) Create a "blob:" URL from that Blob
//     const objectUrl = URL.createObjectURL(blob);
//     //console.log('[Saga] Created blob URL:', objectUrl);

//     // 4) Put the same success action as before, but pass this blob: URL
//     yield put(fetchSignedUrlSuccess(objectUrl));
//     // console.log('[Saga] Dispatched fetchSignedUrlSuccess with blob URL.');

//   } catch (error) {
//     console.error('Error fetching audio stream:', error);
//     yield put(fetchSignedUrlFailure(error.message));
//   }
// }


//   // function* fetchPdfSignedUrlSaga(action) {
//   //   const { call_id } = action.payload;
//   //   try {
//   //     const token = localStorage.getItem('token');
//   //     const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
  
//   //     // GET /api/pdf/generate-pdf-signed-url/:call_id
//   //     const response = yield call(fetch, `${fetchUrl}/api/pdf/generate-pdf-signed-url/${encodeURIComponent(call_id)}`, {
//   //       headers: {
//   //         'Authorization': `Bearer ${token}`,
//   //         'Content-Type': 'application/json',
//   //       },
//   //     });
  
//   //     if (!response.ok) {
//   //       throw new Error(`Failed to fetch PDF signed URL. Status: ${response.status}`);
//   //     }
  
//   //     const data = yield response.json(); // Expect { url: '...' }
  
//   //     // Dispatch success with data.url using the action creator
//   //     yield put(fetchPdfSignedUrlSuccess(data.url));
  
//   //   } catch (error) {
//   //     console.error('Error fetching PDF signed URL:', error);
//   //     yield put(fetchPdfSignedUrlFailure(error.message));
//   //   }
//   // }

//   function* fetchPdfSignedUrlSaga(action) {
//     const { call_id } = action.payload;
//     try {
//       const token = localStorage.getItem('token');
//       const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
  
//       // 1) Call the new streaming route
//       const response = yield call(fetch, `${fetchUrl}/api/pdf/pdf-stream/${encodeURIComponent(call_id)}`, {
//         method: 'GET',
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           // no need for "Content-Type: application/json" - we're fetching binary
//         },
//       });
  
//       if (!response.ok) {
//         throw new Error(`Failed to fetch PDF stream. Status: ${response.status}`);
//       }
  
//       // 2) Convert to a Blob
//       const blob = yield call([response, response.blob]);
  
//       // 3) Create a blob URL
//       const objectUrl = URL.createObjectURL(blob);
  
//       // 4) Put the success action with your new "pdfUrl" = objectUrl
//       yield put(fetchPdfSignedUrlSuccess(objectUrl));
  
//     } catch (error) {
//       console.error('Error fetching PDF stream:', error);
//       yield put(fetchPdfSignedUrlFailure(error.message));
//     }
//   }
  
// function* inboundCallsSaga() {
//   yield takeEvery('FETCH_INBOUND_CALLS', fetchInboundCallsSaga);
//   yield takeEvery('FETCH_SIGNED_URL_REQUEST', fetchSignedUrlSaga);
//   yield takeEvery('FETCH_PDF_SIGNED_URL_REQUEST', fetchPdfSignedUrlSaga);
// }

// export default inboundCallsSaga;

// sagas/inboundCallsSaga.js
import { call, put, takeEvery } from 'redux-saga/effects';
import axiosInstance from '../../api/axios';
import { fetchInboundCallsSuccess, fetchInboundCallsFailure, fetchSignedUrlSuccess, fetchSignedUrlFailure, fetchPdfSignedUrlSuccess, fetchPdfSignedUrlFailure } from '../actions/inboundCallsActions';

function* fetchInboundCallsSaga() {
  try {
    const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
    const response = yield call(axiosInstance.get, `${fetchUrl}/api/calls/inbound-calls-company-user`);
    console.log('Inbound calls response:', response.data); // Debug
    yield put(fetchInboundCallsSuccess(response.data));
  } catch (error) {
    console.error('Error fetching inbound calls:', error.response?.data || error.message);
    yield put(fetchInboundCallsFailure(error.response?.data?.message || 'Network error'));
  }
}

function* fetchSignedUrlSaga(action) {
  const { call_id } = action.payload;
  try {
    const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
    const response = yield call(axiosInstance.get, `${fetchUrl}/api/audios/audio-stream/${encodeURIComponent(call_id)}`);
    console.log('Signed URL response status:', response.status); // Debug

    // Convert response to Blob
    const blob = yield call([response, 'blob']); // Assuming axios response.blob() works
    const objectUrl = URL.createObjectURL(blob);
    console.log('Created blob URL:', objectUrl); // Debug

    yield put(fetchSignedUrlSuccess(objectUrl));
  } catch (error) {
    console.error('Error fetching audio stream:', error.response?.data || error.message);
    yield put(fetchSignedUrlFailure(error.response?.data?.message || 'Failed to fetch audio stream'));
  }
}

function* fetchPdfSignedUrlSaga(action) {
  const { call_id } = action.payload;
  try {
    const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
    const response = yield call(axiosInstance.get, `${fetchUrl}/api/pdf/pdf-stream/${encodeURIComponent(call_id)}`);
    console.log('PDF URL response status:', response.status); // Debug

    // Convert response to Blob
    const blob = yield call([response, 'blob']); // Assuming axios response.blob() works
    const objectUrl = URL.createObjectURL(blob);
    console.log('Created PDF blob URL:', objectUrl); // Debug

    yield put(fetchPdfSignedUrlSuccess(objectUrl));
  } catch (error) {
    console.error('Error fetching PDF stream:', error.response?.data || error.message);
    yield put(fetchPdfSignedUrlFailure(error.response?.data?.message || 'Failed to fetch PDF stream'));
  }
}

function* inboundCallsSaga() {
  yield takeEvery('FETCH_INBOUND_CALLS', fetchInboundCallsSaga);
  yield takeEvery('FETCH_SIGNED_URL_REQUEST', fetchSignedUrlSaga);
  yield takeEvery('FETCH_PDF_SIGNED_URL_REQUEST', fetchPdfSignedUrlSaga);
}

export default inboundCallsSaga;