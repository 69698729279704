import { all } from 'redux-saga/effects';
//import loginSaga from './loginSaga';
import watchLogout from './logoutSaga';
//import  registerSaga  from './registerSaga';
import { watchFetchConfig } from './configSaga';
import authSaga from './authSaga';
import inboundCallsSaga from './inboundCallsSaga';
import outboundCallsSaga from './outboundCallsSaga';
import {watchTwilio} from './twilioSaga';

export default function* rootSaga() {
  //console.log('RootSaga: Starting - All sagas should load');
  yield all([
    //loginSaga(),
    //registerSaga(),
    watchFetchConfig(),
    watchLogout(),
    authSaga(),
    inboundCallsSaga(),
    outboundCallsSaga(),
    watchTwilio(),
  ]);
}
