// import { put, takeLatest } from 'redux-saga/effects';
// import { logoutSuccess } from '../actions/loginActions';

// function* logoutSaga() {
//   try {
//     localStorage.removeItem('token'); // Clear token
//     yield put(logoutSuccess()); // Reset state
//   } catch (error) {
//     console.error('Logout failed:', error);
//   }
// }

// export default function* watchLogout() {
//   yield takeLatest('LOGOUT_REQUESTED', logoutSaga);
// }

// sagas/logoutSaga.js
import { call, put, takeLatest } from 'redux-saga/effects';
import axiosInstance from '../../api/axios';
import { logoutSuccess } from '../actions/authActions'; // Adjusted to match authSaga.js

function* logoutSaga() {
  try {
    const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
    // Optional: Call server-side logout to clear cookie
    yield call(axiosInstance.post, `${fetchUrl}/auth/logout`);
    console.log('Logout request sent'); // Debug
    yield put(logoutSuccess()); // Reset state
  } catch (error) {
    console.error('Logout failed:', error.response?.data || error.message);
    yield put(logoutSuccess()); // Still reset state on error to ensure client-side logout
  }
}

export default function* watchLogout() {
  yield takeLatest('LOGOUT_REQUESTED', logoutSaga);
}
