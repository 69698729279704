// import { call, put, takeLatest, all } from 'redux-saga/effects';
// // import axios from 'axios';
// import axiosInstance from '../../api/axios';
// import { authSuccess, authFailed, logoutSuccess } from '../actions/authActions';

// // Helper to set token in Axios headers and localStorage
// // function setAuthToken(token) {
// //   if (token) {
// //     axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
// //     localStorage.setItem('token', token); // Store token in localStorage
// //   } else {
// //     delete axiosInstance.defaults.headers.common['Authorization'];
// //     localStorage.removeItem('token'); // Remove token from localStorage
// //   }
// // }

// // Helper to get the correct API base URL for production or development
// const getApiBaseUrl = () => {
//   // return process.env.NODE_ENV === 'production'
//   //   ? 'https://your-production-url.com'
//   //   : 'http://localhost:5000';
//   const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
//   console.log('In sagas, this is API URL:', fetchUrl);
//   return fetchUrl;
// };

// // Unified auth handler for both login and registration
// function* handleAuth(action) {
//   const { credentials, actionType } = action.payload;
//   const apiUrl = getApiBaseUrl(); // Get the correct API base URL
//   const endpoint = actionType === 'login' ? '/auth/login' : '/auth/register';

//   try {
//     const response = yield call(axiosInstance.post, `${apiUrl}${endpoint}`, credentials);

//     const { user } = response.data; // Extract user and token from response
//     // console.log('Auth Response:', response.data);

//     // Store token and user in localStorage for persistence
//     // setAuthToken(token);
//     // localStorage.setItem('user', JSON.stringify(user));

//     // Dispatch success action with user and token to Redux
//     yield put(authSuccess({ user }));
//   } catch (error) {
//     console.error('Authentication failed:', error);
//     yield put(authFailed(error.response?.data?.message || 'Authentication failed.'));
//   }
// }

// function* handleLogout() {
//   console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^Logging out...');
//   // Clear the token from axios headers
//   //setAuthToken(null); 

//   // Remove user and token from localStorage explicitly
//   localStorage.removeItem('user');
//   //localStorage.removeItem('token');

//   // Now dispatch the logout success action, which resets the Redux state
//   yield put(logoutSuccess());
// }

// function* handleTokenExpired() {
//   // Clear token and user data
//   //setAuthToken(null);
//   localStorage.removeItem('user');
  
//   // Dispatch the action to update the Redux store
//   yield put(logoutSuccess());
// }

// // Watcher saga for authentication and logout actions
// export default function* authSaga() {
//   yield all([
//     takeLatest('AUTH_REQUESTED', handleAuth),
//     takeLatest('LOGOUT_REQUESTED', handleLogout),
//     takeLatest('TOKEN_EXPIRED', handleTokenExpired),
//   ]);
// }

// authSaga.js
import { call, put, takeLatest, all } from 'redux-saga/effects';
import axiosInstance from '../../api/axios';
// import { authSuccess, authFailed, logoutSuccess } from '../actions/authActions';
import {
  authRequested,
  authSuccess, // From reducer
  authFailed,
  logoutRequested,
  logoutSuccess,
  logoutFailed,
} from '../reducers/authReducer'; // Fix import

// Helper to get the correct API base URL for production or development
const getApiBaseUrl = () => {
  const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
  console.log('In sagas, this is API URL:', fetchUrl);
  return fetchUrl;
};

// Unified auth handler for both login and registration
function* handleAuth(action) {
  console.log("Saga was requested________________")
  const { credentials, actionType } = action.payload;
  const apiUrl = getApiBaseUrl(); // Get the correct API base URL
  const endpoint = actionType === 'login' ? '/auth/login' : '/auth/register';
  console.log('Saga: Sending request to:', `${apiUrl}${endpoint}`, 'Credentials:', credentials);

  try {
    console.log('Saga: Starting axios call...');
    const response = yield call(axiosInstance.post, `${apiUrl}${endpoint}`, credentials);
    console.log('Saga: Response Recieved:', response.data);
    const { user } = response.data; // Extract only user, token is in localStorage
    // console.log('Auth Response:', response.data);
    // localStorage.setItem('token', token);
    console.log('Saga: User extracted:', user);
    yield put(authSuccess({ user }));
    console.log('Saga: authSuccess dispatched');
  } catch (error) {
    console.error('Authentication failed:', error);
    yield put(authFailed(error.response?.data?.message || 'Authentication failed.'));
  }
}

function* handleLogout() {
  console.log('Saga: Logout requested received');
  try {
    const apiUrl = getApiBaseUrl();
    console.log('Saga: Sending logout request to:', `${apiUrl}/auth/logout`);
    yield call(axiosInstance.post, `${apiUrl}/auth/logout`);
    console.log('Saga: Logout request successful');
  } catch (err) {
    console.error('Saga: Logout request failed:', err);
    // Still proceed with client-side logout even if server call fails
  }
  yield put(logoutSuccess());
  console.log('Saga: logoutSuccess dispatched');
}


// function* handleTokenExpired() {
//   // No localStorage to clear; rely on Redux reset
//   yield put(logoutSuccess());
// }

// Watcher saga for authentication and logout actions
export default function* authSaga() {
  yield all([
    takeLatest('AUTH_REQUESTED', handleAuth),
    takeLatest('LOGOUT_REQUESTED', handleLogout),
    //takeLatest('TOKEN_EXPIRED', handleTokenExpired),
  ]);
}
