// import { call, put, takeEvery } from 'redux-saga/effects';
// import { fetchOutboundCallsSuccess, fetchOutboundCallsFailure, fetchSignedUrlSuccess, fetchSignedUrlFailure } from '../actions/outboundCallsActions';

// function* fetchOutboundCallsSaga() {
//   try {
//     // console.log("Hey yo!!!!!! i am here in fetchOutboundCallsSaga");
//     const token = localStorage.getItem('token');
//     const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
//     const response = yield call(fetch, `${fetchUrl}/api/calls/outbound-calls-company-user`, {
//       method: 'GET',
//       headers: {
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       }
//     });

//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }

//     const data = yield response.json();
//     yield put(fetchOutboundCallsSuccess(data));
//   } catch (error) {
//     console.error('Error fetching outbound calls:', error);
//     yield put(fetchOutboundCallsFailure(error.message));
//   }
// }

// function* fetchSignedUrlSaga(action) {
//     const { call_id } = action.payload;
//     try {
//       const token = localStorage.getItem('token');
//       const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
//       const response = yield call(fetch, `${fetchUrl}/api/audios/generate-signed-url/${encodeURIComponent(call_id)}`, {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           'Content-Type': 'application/json'
//         }
//       });
  
//       if (!response.ok) {
//         throw new Error('Failed to fetch signed URL');
//       }
  
//       const data = yield response.json();
//       yield put(fetchSignedUrlSuccess(data.url));
//     } catch (error) {
//       console.error('Error fetching signed URL:', error);
//       yield put(fetchSignedUrlFailure(error.message));
//     }
//   }

// function* outboundCallsSaga() {
//   yield takeEvery('FETCH_OUTBOUND_CALLS', fetchOutboundCallsSaga);
//   yield takeEvery('FETCH_SIGNED_URL_REQUEST', fetchSignedUrlSaga);
// }

// export default outboundCallsSaga;

// sagas/outboundCallsSaga.js
import { call, put, takeEvery } from 'redux-saga/effects';
import axiosInstance from '../../api/axios';
import { fetchOutboundCallsSuccess, fetchOutboundCallsFailure, fetchSignedUrlSuccess, fetchSignedUrlFailure } from '../actions/outboundCallsActions';

function* fetchOutboundCallsSaga() {
  try {
    // console.log("Hey yo!!!!!! i am here in fetchOutboundCallsSaga");
    const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
    const response = yield call(axiosInstance.get, `${fetchUrl}/api/calls/outbound-calls-company-user`);
    console.log('Outbound calls response:', response.data); // Debug
    yield put(fetchOutboundCallsSuccess(response.data));
  } catch (error) {
    console.error('Error fetching outbound calls:', error.response?.data || error.message);
    yield put(fetchOutboundCallsFailure(error.response?.data?.message || 'Network error'));
  }
}

function* fetchSignedUrlSaga(action) {
  const { call_id } = action.payload;
  try {
    const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
    const response = yield call(axiosInstance.get, `${fetchUrl}/api/audios/generate-signed-url/${encodeURIComponent(call_id)}`);
    console.log('Signed URL response:', response.data); // Debug
    yield put(fetchSignedUrlSuccess(response.data.url));
  } catch (error) {
    console.error('Error fetching signed URL:', error.response?.data || error.message);
    yield put(fetchSignedUrlFailure(error.response?.data?.message || 'Failed to fetch signed URL'));
  }
}

function* outboundCallsSaga() {
  yield takeEvery('FETCH_OUTBOUND_CALLS', fetchOutboundCallsSaga);
  yield takeEvery('FETCH_SIGNED_URL_REQUEST', fetchSignedUrlSaga);
}

export default outboundCallsSaga;