// // redux/store.js
// import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // Uses localStorage
// import createSagaMiddleware from 'redux-saga';
// import rootSaga from '../redux/sagas/index';
// import authReducer from './reducers/authReducer'; 
// import uiReducer from './slices/uiSlice';
// import inboundCallsReducer from './reducers/inboundCallsReducer';
// import outboundCallsReducer from './reducers/outboundCallsReducer';
// import twilioReducer from './reducers/twilioReducer';

// // Configure persistence
// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['auth'], // Specify which reducers to persist
// };

// const rootReducer = combineReducers({
//   auth: authReducer,
//   inboundCalls: inboundCallsReducer,
//   outboundCalls: outboundCallsReducer,
//   twilio: twilioReducer,
//     // ui: uiReducer,
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const sagaMiddleware = createSagaMiddleware();

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       thunk: false,
//       serializableCheck: false, // Ignore serialization checks for redux-persist
//     }).concat(sagaMiddleware),
// });

// sagaMiddleware.run(rootSaga);

// export const persistor = persistStore(store); // Create the persistor
// export default store;

// redux/store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../redux/sagas/index';
import authReducer from './reducers/authReducer';
import uiReducer from './slices/uiSlice';
import inboundCallsReducer from './reducers/inboundCallsReducer';
import outboundCallsReducer from './reducers/outboundCallsReducer';
import twilioReducer from './reducers/twilioReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  inboundCalls: inboundCallsReducer,
  outboundCalls: outboundCallsReducer,
  twilio: twilioReducer,
  // ui: uiReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
