// Lookup Actions
export const TWILIO_LOOKUP_REQUEST = 'TWILIO_LOOKUP_REQUEST';
export const TWILIO_LOOKUP_SUCCESS = 'TWILIO_LOOKUP_SUCCESS';
export const TWILIO_LOOKUP_FAILURE = 'TWILIO_LOOKUP_FAILURE';

export const twilioLookupRequest = (payload) => ({
  type: TWILIO_LOOKUP_REQUEST,
  payload, // Expected: { areaCode, friendlyName }
});

export const twilioLookupSuccess = (numbers) => ({
  type: TWILIO_LOOKUP_SUCCESS,
  payload: numbers, // array of available numbers
});

export const twilioLookupFailure = (error) => ({
  type: TWILIO_LOOKUP_FAILURE,
  payload: error,
});

export const FETCH_AGENTS_REQUEST = 'FETCH_AGENTS_REQUEST';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_FAILURE = 'FETCH_AGENTS_FAILURE';

export const fetchAgentsRequest = () => ({ type: FETCH_AGENTS_REQUEST });
export const fetchAgentsSuccess = (agents) => ({ type: FETCH_AGENTS_SUCCESS, payload: agents });
export const fetchAgentsFailure = (error) => ({ type: FETCH_AGENTS_FAILURE, payload: error });

// Fetch Retell Agent (for missing llm_id)
export const FETCH_RETELL_AGENT_REQUEST = 'FETCH_RETELL_AGENT_REQUEST';
export const FETCH_RETELL_AGENT_SUCCESS = 'FETCH_RETELL_AGENT_SUCCESS';
export const FETCH_RETELL_AGENT_FAILURE = 'FETCH_RETELL_AGENT_FAILURE';

export const fetchRetellAgentRequest = (agent_id) => ({ type: FETCH_RETELL_AGENT_REQUEST, payload: { agent_id } });
export const fetchRetellAgentSuccess = (agentData) => ({ type: FETCH_RETELL_AGENT_SUCCESS, payload: agentData });
export const fetchRetellAgentFailure = (error) => ({ type: FETCH_RETELL_AGENT_FAILURE, payload: error });

// Purchase Actions
export const TWILIO_PURCHASE_REQUEST = 'TWILIO_PURCHASE_REQUEST';
export const TWILIO_PURCHASE_SUCCESS = 'TWILIO_PURCHASE_SUCCESS';
export const TWILIO_PURCHASE_FAILURE = 'TWILIO_PURCHASE_FAILURE';

export const twilioPurchaseRequest = (payload) => ({
  type: TWILIO_PURCHASE_REQUEST,
  payload, // Expected: { selectedNumber, friendlyName }
});

export const twilioPurchaseSuccess = (purchaseData) => ({
  type: TWILIO_PURCHASE_SUCCESS,
  payload: {
    purchasedNumber: purchaseData.purchasedNumber,
    phoneNumberSid: purchaseData.phoneNumberSid,
  },
});

export const twilioPurchaseFailure = (error) => ({
  type: TWILIO_PURCHASE_FAILURE,
  payload: error,
});

// Associate Actions
export const TWILIO_ASSOCIATE_REQUEST = 'TWILIO_ASSOCIATE_REQUEST';
export const TWILIO_ASSOCIATE_SUCCESS = 'TWILIO_ASSOCIATE_SUCCESS';
export const TWILIO_ASSOCIATE_FAILURE = 'TWILIO_ASSOCIATE_FAILURE';

export const twilioAssociateRequest = (payload) => ({
  type: TWILIO_ASSOCIATE_REQUEST,
  payload, // Expected: { selectedNumber }
});

export const twilioAssociateSuccess = (associationData) => ({
  type: TWILIO_ASSOCIATE_SUCCESS,
  payload: associationData,
});

export const twilioAssociateFailure = (error) => ({
  type: TWILIO_ASSOCIATE_FAILURE,
  payload: error,
});

// Import Actions
export const TWILIO_IMPORT_REQUEST = 'TWILIO_IMPORT_REQUEST';
export const TWILIO_IMPORT_SUCCESS = 'TWILIO_IMPORT_SUCCESS';
export const TWILIO_IMPORT_FAILURE = 'TWILIO_IMPORT_FAILURE';

export const twilioImportRequest = (payload) => ({
  type: TWILIO_IMPORT_REQUEST,
  payload, // Expected: { phoneNumber, terminationUri }
});

export const twilioImportSuccess = (importData) => ({
  type: TWILIO_IMPORT_SUCCESS,
  payload: importData,
});

export const twilioImportFailure = (error) => ({
  type: TWILIO_IMPORT_FAILURE,
  payload: error,
});

// LLM Creation Actions
export const CREATE_RETELL_LLM_REQUEST = 'CREATE_RETELL_LLM_REQUEST';
export const CREATE_RETELL_LLM_SUCCESS = 'CREATE_RETELL_LLM_SUCCESS';
export const CREATE_RETELL_LLM_FAILURE = 'CREATE_RETELL_LLM_FAILURE';

export const createRetellLLMRequest = (payload) => ({
  type: CREATE_RETELL_LLM_REQUEST,
  payload, // Expected: { model, general_prompt, general_tools }
});

export const createRetellLLMSuccess = (llmDetails) => ({
  type: CREATE_RETELL_LLM_SUCCESS,
  payload: llmDetails,
});

export const createRetellLLMFailure = (error) => ({
  type: CREATE_RETELL_LLM_FAILURE,
  payload: error,
});

export const CREATE_RETELL_AGENT_REQUEST = 'CREATE_RETELL_AGENT_REQUEST';
export const CREATE_RETELL_AGENT_SUCCESS = 'CREATE_RETELL_AGENT_SUCCESS';
export const CREATE_RETELL_AGENT_FAILURE = 'CREATE_RETELL_AGENT_FAILURE';

export const createRetellAgentRequest = (payload) => ({
  type: CREATE_RETELL_AGENT_REQUEST,
  payload,
});

export const createRetellAgentSuccess = (agentDetails) => ({
  type: CREATE_RETELL_AGENT_SUCCESS,
  payload: agentDetails,
});

export const createRetellAgentFailure = (error) => ({
  type: CREATE_RETELL_AGENT_FAILURE,
  payload: error,
});

export const ASSIGN_PHONE_NUMBER_REQUEST = 'ASSIGN_PHONE_NUMBER_REQUEST';
export const ASSIGN_PHONE_NUMBER_SUCCESS = 'ASSIGN_PHONE_NUMBER_SUCCESS';
export const ASSIGN_PHONE_NUMBER_FAILURE = 'ASSIGN_PHONE_NUMBER_FAILURE';

export const assignPhoneNumberRequest = (payload) => ({
  type: ASSIGN_PHONE_NUMBER_REQUEST,
  payload, // { phoneNumber, agentId }
});

export const assignPhoneNumberSuccess = (assignmentDetails) => ({
  type: ASSIGN_PHONE_NUMBER_SUCCESS,
  payload: assignmentDetails,
});

export const assignPhoneNumberFailure = (error) => ({
  type: ASSIGN_PHONE_NUMBER_FAILURE,
  payload: error,
});

export const FETCH_LLM_REQUEST = 'FETCH_LLM_REQUEST';
export const FETCH_LLM_SUCCESS = 'FETCH_LLM_SUCCESS';
export const FETCH_LLM_FAILURE = 'FETCH_LLM_FAILURE';

export const fetchLLMRequest = (llm_id) => ({ type: FETCH_LLM_REQUEST, payload: { llm_id } });
export const fetchLLMSuccess = (llmData) => ({ type: FETCH_LLM_SUCCESS, payload: llmData });
export const fetchLLMFailure = (error) => ({ type: FETCH_LLM_FAILURE, payload: error });

// Update LLM (DB and Lambda)
export const UPDATE_LLM_REQUEST = 'UPDATE_LLM_REQUEST';
export const UPDATE_LLM_SUCCESS = 'UPDATE_LLM_SUCCESS';
export const UPDATE_LLM_FAILURE = 'UPDATE_LLM_FAILURE';

export const updateLLMRequest = (payload) => ({ type: UPDATE_LLM_REQUEST, payload });
export const updateLLMSuccess = (llmData) => ({ type: UPDATE_LLM_SUCCESS, payload: llmData });
export const updateLLMFailure = (error) => ({ type: UPDATE_LLM_FAILURE, payload: error });

export const CREATE_LLM_COMPLETE = 'CREATE_LLM_COMPLETE';
export const createLLMComplete = (llmDetails, agentName, questions) => ({
  type: CREATE_LLM_COMPLETE,
  payload: { llmDetails, agentName, questions },
});

export const RESET_AGENT_CREATION = 'RESET_AGENT_CREATION';
export const resetAgentCreation = () => ({ type: RESET_AGENT_CREATION });

export const INVOKE_BEDROCK_AGENT_REQUEST = 'INVOKE_BEDROCK_AGENT_REQUEST';
export const INVOKE_BEDROCK_AGENT_SUCCESS = 'INVOKE_BEDROCK_AGENT_SUCCESS';
export const INVOKE_BEDROCK_AGENT_FAILURE = 'INVOKE_BEDROCK_AGENT_FAILURE';

export const invokeBedrockAgentRequest = (payload) => ({ type: INVOKE_BEDROCK_AGENT_REQUEST, payload });
export const invokeBedrockAgentSuccess = (result) => ({ type: INVOKE_BEDROCK_AGENT_SUCCESS, payload: result });
export const invokeBedrockAgentFailure = (error) => ({ type: INVOKE_BEDROCK_AGENT_FAILURE, payload: error });