import React from 'react';
import '../css/LanguageContainer.css';
import Globe from '../img/GLOBE2.png';

function LanguageContainer() {
  return (
    <div className="lang-container">
      <h6 className='off-css-12'>Feature Highlights</h6>
      <div className="language-section">
        <div className="lang-text-content">
          <h6 className="lang-section-heading">Languages!</h6>
          <p className="lang-91e">We offer multiple languages with translations into English if needed.</p>
        </div>
        <div className='image-rs-10'>
          <img alt="Globe with Flags of the World" src={Globe} className="globe-image" />
        </div>
      </div>
    </div>
  );
}

export default LanguageContainer;
