import { createAction } from '@reduxjs/toolkit';

// Login and Registration Actions
export const authRequested = createAction('AUTH_REQUESTED');
export const authSuccess = createAction('AUTH_SUCCESS'); 
export const authFailed = createAction('AUTH_FAILED');

// Logout Actions
export const logoutRequested = createAction('LOGOUT_REQUESTED');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const tokenExpired = createAction('TOKEN_EXPIRED');
