import React from 'react';
import '../css/AvailabilityContainer.css';
import Clock from '../img/coolneonclock.png';

function LanguageContainer() {
  return (
    <div className="avail-container">
      <div className="dutch-container">
        <div className="avail-section">
          <div className="avail-text-content">
            <h6 className="avail-section-heading">Available 24/7</h6>
            <p className="avail-91e">Continuous availability.  Always be communicating even during off-hours.</p>
          </div>
          <div className="clock-img-content">
          <img alt="Clock to tell the time" src={Clock} className="clock-image" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LanguageContainer;
