import React from 'react';
import '../css/Expertise.css';
import Bulb from '../img/bulb.png';

function Expertise() {
  return (
    <div className="expertise-container">
      <div className="expertise-section">
        <div className="expertise-text-content">
          <h6 className="expertise-section-heading">AI Powered by Expertise</h6>
          <p className="expertise-lang-91e">Enjoy superior service backed by exclusive expert-sourced HR data and more -
moving beyond standard market tech for better results that set you
apart!</p>
        </div>
        <div className='exp-image-rs-10'>
          <img alt="Thought Gears, not to be confused with Richard Gere" src={Bulb} className="bulb-image" />
        </div>
      </div>
    </div>
  );
}

export default Expertise;
