import React from 'react';
//import Navbar from '../../components/Navbar';
import './About.css'; 
import People from '../../img/neonppl.png';
import LinksContainer from '../../components/LinksContainer';

function About() {
  return (
    <div className="about-page">
      <div className="about-container">
        <div className="about-container">
          <h3 className="about-title">Our Values</h3>
          <h3 className="about-subtitle">User-Centricity</h3>
          <p className="about-body">We put the user’s needs and experiences first, ensuring the technology serves and enhances their journey. </p>
          <h3 className="about-subtitle">Transparency and Trust</h3>
          <p className="about-body">We want long-standing relationships with our users, and as a result, we strongly believe that it starts with transparency and trust in all we do.</p>
          <h3 className="about-subtitle">Innovation with Integrity</h3>
          <p className="about-body">Continuously pushing the boundaries of technology while upholding ethical standards, ensuring that AI-driven solutions are fair, and aligned with best practices.</p>
          <h3 className="about-subtitle">Continuous Learning and Adaptability</h3>
          <p className="about-body">Embracing feedback and rapidly evolving in response to user needs and advancements in AI technology, ensuring the platform remains relevant, effective, and beneficial in a dynamic landscape.</p>
          <div className="about-img-div">
            <img alt="That's my people!  They're neon like that" src={People} className="neon-peeps-img" />
          </div>
          <p className="about-body">At VettedVoice, we are revolutionizing all things work by leveraging the power of voice AI technology. Our mission is to simplify and streamline tedious and often times manual processes experience for businesses.</p>
          <p className="about-body">We understand that traditional methods can be time-consuming, inefficient, and often biased. That's why we've created a service that allows for seamless work tasks in many areas through natural voice conversations. Our AI-powered system can analyze these conversations to provide actionable intelligence and insights, saving businesses valuable time and resources.</p>
          <p className="about-body">We prioritize the security and privacy of all user data. VettedVoice complies with industry-standard security protocols to protect sensitive information.</p>
          <p className="about-body">We are committed to providing a flawless and intuitive user experience for all. Our service is easy to use and accessible from any device, making it convenient for anyone.</p>
          <p className="about-body">Join VettedVoice today and experience the future. Let us help you.  Together, let's make the work process more efficient, effective, and enjoyable for everyone.</p>
        </div>
      </div>
      <LinksContainer />
    </div>
  );
}

export default About;
