// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import '../css/AdminDashboard.css';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import { useDispatch, useSelector } from 'react-redux';
// import { logoutRequested } from '../redux/actions/authActions';
// import { useNavigate } from 'react-router-dom';


// function AdminDashboard() {
//   const [blogs, setBlogs] = useState([]);
//   const [title, setTitle] = useState('');
//   const [info, setInfo] = useState('');
//   const [content, setContent] = useState('');
//   const [heroImageUrl, setHeroImageUrl] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedBlog, setSelectedBlog] = useState(null);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [deleteConfirmation, setDeleteConfirmation] = useState(false); // State for delete modal
//   const [blogToDelete, setBlogToDelete] = useState(null);
//   const [isEditMode, setIsEditMode] = useState(false);

//   const navigate = useNavigate();
//   const dispatch = useDispatch();
  

//   const fetchBlogs = async () => {
//     setLoading(true);
//     try {
//       const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
//       console.log('API URL:', fetchUrl);
//       const response = await axios.get(`${fetchUrl}/api/blog`);
//       const data = response.data;
  
//       if (Array.isArray(data)) {
//         const sortedBlogs = data.sort((a, b) => new Date(b.published_date) - new Date(a.published_date));
//         setBlogs(sortedBlogs);
//       } else {
//         setBlogs([]);
//       }
//     } catch (error) {
//       console.error('Error fetching blogs:', error);
//       setError('Failed to fetch blogs.');
//     } finally {
//       setLoading(false);
//     }
//   };

  

//   const addBlog = async () => {
//     const blogData = {
//       title,
//       info,
//       content,
//       hero_image: heroImageUrl,
//     };

//     const token = localStorage.getItem('token');
  
//     try {
//       const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
//       const response = await axios.post(
//         `${fetchUrl}/api/blog/add`,
//         blogData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
  
//       setBlogs([response.data.blog, ...blogs]);
//       setTitle('');
//       setInfo('');
//       setContent('');
//       setHeroImageUrl('');
//     } catch (error) {
//       console.error('Error adding blog:', error);
//     }
//   };

//   const openModal = (blog, pushState = true) => {
//     setSelectedBlog(blog);
//     setIsModalOpen(true);
//     document.body.classList.add('no-scroll');

//     if (pushState) {
//       const urlParams = new URLSearchParams(window.location.search);
//       urlParams.set('p', blog.id);
//       urlParams.set('pm', 'c');
//       window.history.pushState(null, '', `${window.location.pathname}?${urlParams.toString()}`);
//     }
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedBlog(null);
//     document.body.classList.remove('no-scroll'); // Enable body scrolling

//     const urlParams = new URLSearchParams(window.location.search);
//     urlParams.delete('p');
//     urlParams.delete('pm');

//     const newSearch = urlParams.toString();
//     const newUrl = newSearch ? `${window.location.pathname}?${newSearch}` : window.location.pathname;

//     window.history.pushState(null, '', newUrl);
//   };

//   const editBlog = async () => {
//     if (!selectedBlog) return;

//     const updatedBlog = {
//       title,
//       info,
//       content,
//       hero_image: heroImageUrl,
//     };

//     const token = localStorage.getItem('token');

//     try {
//       console.log("hitting edit like a big boy");
//       const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
//       console.log('API URL:', fetchUrl);
//       const response = await axios.put(
//         `${fetchUrl}/api/blog/edit/${selectedBlog.id}`,
//         updatedBlog,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );

//       setBlogs(blogs.map(blog => (blog.id === selectedBlog.id ? response.data.blog : blog)));
//       resetForm();  // Reset form after editing
//       setIsEditMode(false);
//     } catch (error) {
//       console.error('Error updating blog:', error);
//     }
//   };

//   const handleEditClick = (blog, e) => {
//     e.stopPropagation();
//     setSelectedBlog(blog);
//     setTitle(blog.title);
//     setInfo(blog.info);
//     setContent(blog.content);
//     setHeroImageUrl(blog.hero_image);
//     setIsEditMode(true); // Enable edit mode
//   };

//   const handleDeleteClick = (blog, e) => {
//     e.stopPropagation();
//     console.log(`Attempting to delete blog:`, blog);
//     setBlogToDelete(blog); // Set which blog to delete
//     setDeleteConfirmation(true); // Show the confirmation modal
//   };

//   const confirmDelete = async () => {
//     if (!blogToDelete) return;

//     console.log(`Confirming delete for blog ID: ${blogToDelete.id}`);

//     const token = localStorage.getItem('token');

//     try {
//       const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
//       await axios.delete(`${fetchUrl}/api/blog/delete/${blogToDelete.id}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       setBlogs(blogs.filter((blog) => blog.id !== blogToDelete.id)); // Remove blog from state
//       setDeleteConfirmation(false); // Close confirmation modal
//     } catch (error) {
//       console.error('Error deleting blog:', error);
//     }
//   };

//   const cancelDelete = () => {
//     setDeleteConfirmation(false);
//     setBlogToDelete(null);
//   };

//   const resetForm = () => {
//     setSelectedBlog(null);
//     setTitle('');
//     setInfo('');
//     setContent('');
//     setHeroImageUrl('');
//   };


//   const cancelEdit = () => {
//     resetForm();
//     setIsEditMode(false);
//   };

//   useEffect(() => {
//     fetchBlogs();
//   }, []);

//   // Handle browser navigation (back/forward buttons)
//   useEffect(() => {
//     const handlePopState = () => {
//       const urlParams = new URLSearchParams(window.location.search);
//       const blogId = urlParams.get('p');
//       const modalParam = urlParams.get('pm');

//       if (blogId && modalParam === 'c') {
//         const blog = blogs.find((b) => b.id === blogId);
//         if (blog) {
//           openModal(blog, false); // Pass false to avoid pushing history again
//         }
//       } else {
//         if (isModalOpen) {
//           closeModal();
//         }
//       }
//     };

//     window.addEventListener('popstate', handlePopState);
//     return () => {
//       window.removeEventListener('popstate', handlePopState);
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [blogs, isModalOpen]);

//   // Optional: Close modal on Escape key press
//   useEffect(() => {
//     const handleEscape = (event) => {
//       if (event.key === 'Escape' && isModalOpen) {
//         closeModal();
//       }
//     };

//     document.addEventListener('keydown', handleEscape);
//     return () => {
//       document.removeEventListener('keydown', handleEscape);
//     };
//   }, [isModalOpen]);

//   if (loading) {
//     return <p>Loading blogs...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   const handleLogout = () => {
//     dispatch(logoutRequested());
//     navigate('/');
//     console.log("Hey yo!!!!!!");
//   };

//   return (
//     <div className="blog-list-container">
//       {/* <h1>Admin Dashboard</h1> */}
//       <button onClick={handleLogout} className="admin-logout-btn">Logout</button>
//        {/* New Blog Form */}
//        <div className="new-blog-form">
//        <h2>{isEditMode ? 'Edit Blog' : 'Add a New Blog'}</h2>
//         <div className="form-group">
//           <label htmlFor="title">Blog Title</label>
//           <input
//             id="title"
//             type="text"
//             placeholder="Enter blog title"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             className="form-control"
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="info">Blog Info</label>
//           <textarea
//             id="info"
//             placeholder="Enter blog info"
//             value={info}
//             onChange={(e) => setInfo(e.target.value)}
//             className="form-control"
//             rows="3"
//           ></textarea>
//         </div>

//         <div className="form-group">
//           <label htmlFor="content">Blog Content</label>
//           {/* Replace textarea with ReactQuill */}
//           <ReactQuill
//             id="content"
//             value={content}
//             onChange={setContent}
//             className="quill-editor"
//             placeholder='Enter blog content here...'
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="heroImageUrl">Hero Image S3 URL</label>
//           <input
//             id="heroImageUrl"
//             type="text"
//             placeholder="Enter Hero Image URL"
//             value={heroImageUrl}
//             onChange={(e) => setHeroImageUrl(e.target.value)}
//             className="form-control"
//           />
//         </div>

//         <button className="add-blog-button" onClick={isEditMode ? editBlog : addBlog}>
//           {isEditMode ? 'Update Blog' : 'Add Blog'}
//         </button>
//         {isEditMode && <button className="cancel-edit-button" onClick={cancelEdit}>Cancel</button>}
//       </div>

//       <h2>Existing Blogs</h2>
//       {blogs.length === 0 ? (
//         <p>No blogs available</p>
//       ) : (
//         <div className="blog-card-grid">
//           {blogs.map((blog) => (
//             <div key={blog.id} className="blog-card" onClick={() => openModal(blog)}>
//               <img src={blog.hero_image} alt="Hero" className="blog-card-image" />
//               <div className="blog-card-content">
//                 <h2 className="blog-title">{blog.title}</h2>
//                 <p className="blog-info">{blog.info}</p>
//                 <p className="blog-published">
//                   <strong>Published:</strong> {new Date(blog.published_date).toLocaleDateString()}
//                 </p>
//                 <button className="edit-button" onClick={(e) => handleEditClick(blog, e)}>
//                   Edit
//                 </button>
//                 <button className="delete-button" onClick={(e) => handleDeleteClick(blog, e)}>
//                   Delete
//                 </button>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}

//        {/* Delete Confirmation Modal */}
//        {deleteConfirmation && (
//         <div className="modal" onClick={cancelDelete}>
//           <div className="modal-content">
//             <span className="close-button" onClick={cancelDelete}>
//               &times;
//             </span>
//             <h2>Are you sure you want to delete this blog?</h2>
//             <button className="confirm-delete" onClick={confirmDelete}>
//               Yes
//             </button>
//             <button className="cancel-delete" onClick={cancelDelete}>
//               No
//             </button>
//           </div>
//         </div>
//       )}

//       {isModalOpen && selectedBlog && (
//         <div className="modal" onClick={(e) => e.target.className === 'modal' && closeModal()}>
//           <div className="modal-content">
//             <span className="close-button" onClick={closeModal}>&times;</span>
//             <h2>{selectedBlog.title}</h2>
//             <p><strong>Published:</strong> {new Date(selectedBlog.published_date).toLocaleDateString()}</p>
//             <img src={selectedBlog.hero_image} alt="Hero" className="modal-hero-image" />
//             {/* Display content as HTML in read-only mode */}
//             <div dangerouslySetInnerHTML={{ __html: selectedBlog.content }} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
  
  
// }


// export default AdminDashboard;

import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axios'; // Use axiosInstance instead of axios
import '../css/AdminDashboard.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequested } from '../redux/actions/authActions';
import { useNavigate } from 'react-router-dom';

function AdminDashboard() {
  const [blogs, setBlogs] = useState([]);
  const [title, setTitle] = useState('');
  const [info, setInfo] = useState('');
  const [content, setContent] = useState('');
  const [heroImageUrl, setHeroImageUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [blogToDelete, setBlogToDelete] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchBlogs = async () => {
    setLoading(true);
    try {
      const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
      console.log('API URL:', fetchUrl);
      const response = await axiosInstance.get(`${fetchUrl}/api/blog`); // Use axiosInstance
      const data = response.data;

      if (Array.isArray(data)) {
        const sortedBlogs = data.sort((a, b) => new Date(b.published_date) - new Date(a.published_date));
        setBlogs(sortedBlogs);
      } else {
        setBlogs([]);
      }
    } catch (error) {
      console.error('Error fetching blogs:', error.response?.data || error.message);
      setError('Failed to fetch blogs.');
    } finally {
      setLoading(false);
    }
  };

  const addBlog = async () => {
    const blogData = {
      title,
      info,
      content,
      hero_image: heroImageUrl,
    };

    try {
      const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
      const response = await axiosInstance.post(`${fetchUrl}/api/blog/add`, blogData); // No token needed
      setBlogs([response.data.blog, ...blogs]);
      setTitle('');
      setInfo('');
      setContent('');
      setHeroImageUrl('');
    } catch (error) {
      console.error('Error adding blog:', error.response?.data || error.message);
    }
  };

  const openModal = (blog, pushState = true) => {
    setSelectedBlog(blog);
    setIsModalOpen(true);
    document.body.classList.add('no-scroll');

    if (pushState) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('p', blog.id);
      urlParams.set('pm', 'c');
      window.history.pushState(null, '', `${window.location.pathname}?${urlParams.toString()}`);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBlog(null);
    document.body.classList.remove('no-scroll');

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('p');
    urlParams.delete('pm');

    const newSearch = urlParams.toString();
    const newUrl = newSearch ? `${window.location.pathname}?${newSearch}` : window.location.pathname;

    window.history.pushState(null, '', newUrl);
  };

  const editBlog = async () => {
    if (!selectedBlog) return;

    const updatedBlog = {
      title,
      info,
      content,
      hero_image: heroImageUrl,
    };

    try {
      console.log("hitting edit like a big boy");
      const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
      console.log('API URL:', fetchUrl);
      const response = await axiosInstance.put(`${fetchUrl}/api/blog/edit/${selectedBlog.id}`, updatedBlog); // No token needed
      setBlogs(blogs.map(blog => (blog.id === selectedBlog.id ? response.data.blog : blog)));
      resetForm();
      setIsEditMode(false);
    } catch (error) {
      console.error('Error updating blog:', error.response?.data || error.message);
    }
  };

  const handleEditClick = (blog, e) => {
    e.stopPropagation();
    setSelectedBlog(blog);
    setTitle(blog.title);
    setInfo(blog.info);
    setContent(blog.content);
    setHeroImageUrl(blog.hero_image);
    setIsEditMode(true);
  };

  const handleDeleteClick = (blog, e) => {
    e.stopPropagation();
    console.log(`Attempting to delete blog:`, blog);
    setBlogToDelete(blog);
    setDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    if (!blogToDelete) return;

    console.log(`Confirming delete for blog ID: ${blogToDelete.id}`);

    try {
      const fetchUrl = process.env.REACT_APP_FETCH_URL || 'http://localhost:5000';
      await axiosInstance.delete(`${fetchUrl}/api/blog/delete/${blogToDelete.id}`); // No token needed
      setBlogs(blogs.filter((blog) => blog.id !== blogToDelete.id));
      setDeleteConfirmation(false);
    } catch (error) {
      console.error('Error deleting blog:', error.response?.data || error.message);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmation(false);
    setBlogToDelete(null);
  };

  const resetForm = () => {
    setSelectedBlog(null);
    setTitle('');
    setInfo('');
    setContent('');
    setHeroImageUrl('');
  };

  const cancelEdit = () => {
    resetForm();
    setIsEditMode(false);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const blogId = urlParams.get('p');
      const modalParam = urlParams.get('pm');

      if (blogId && modalParam === 'c') {
        const blog = blogs.find((b) => b.id === blogId);
        if (blog) {
          openModal(blog, false);
        }
      } else {
        if (isModalOpen) {
          closeModal();
        }
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogs, isModalOpen]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && isModalOpen) {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isModalOpen]);

  if (loading) {
    return <p>Loading blogs...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleLogout = () => {
    dispatch(logoutRequested());
    navigate('/');
    console.log("Hey yo!!!!!!");
  };

  return (
    <div className="blog-list-container">
      <button onClick={handleLogout} className="admin-logout-btn">Logout</button>
      <div className="new-blog-form">
        <h2>{isEditMode ? 'Edit Blog' : 'Add a New Blog'}</h2>
        <div className="form-group">
          <label htmlFor="title">Blog Title</label>
          <input
            id="title"
            type="text"
            placeholder="Enter blog title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="info">Blog Info</label>
          <textarea
            id="info"
            placeholder="Enter blog info"
            value={info}
            onChange={(e) => setInfo(e.target.value)}
            className="form-control"
            rows="3"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="content">Blog Content</label>
          <ReactQuill
            id="content"
            value={content}
            onChange={setContent}
            className="quill-editor"
            placeholder='Enter blog content here...'
          />
        </div>
        <div className="form-group">
          <label htmlFor="heroImageUrl">Hero Image S3 URL</label>
          <input
            id="heroImageUrl"
            type="text"
            placeholder="Enter Hero Image URL"
            value={heroImageUrl}
            onChange={(e) => setHeroImageUrl(e.target.value)}
            className="form-control"
          />
        </div>
        <button className="add-blog-button" onClick={isEditMode ? editBlog : addBlog}>
          {isEditMode ? 'Update Blog' : 'Add Blog'}
        </button>
        {isEditMode && <button className="cancel-edit-button" onClick={cancelEdit}>Cancel</button>}
      </div>

      <h2>Existing Blogs</h2>
      {blogs.length === 0 ? (
        <p>No blogs available</p>
      ) : (
        <div className="blog-card-grid">
          {blogs.map((blog) => (
            <div key={blog.id} className="blog-card" onClick={() => openModal(blog)}>
              <img src={blog.hero_image} alt="Hero" className="blog-card-image" />
              <div className="blog-card-content">
                <h2 className="blog-title">{blog.title}</h2>
                <p className="blog-info">{blog.info}</p>
                <p className="blog-published">
                  <strong>Published:</strong> {new Date(blog.published_date).toLocaleDateString()}
                </p>
                <button className="edit-button" onClick={(e) => handleEditClick(blog, e)}>
                  Edit
                </button>
                <button className="delete-button" onClick={(e) => handleDeleteClick(blog, e)}>
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {deleteConfirmation && (
        <div className="modal" onClick={cancelDelete}>
          <div className="modal-content">
            <span className="close-button" onClick={cancelDelete}>×</span>
            <h2>Are you sure you want to delete this blog?</h2>
            <button className="confirm-delete" onClick={confirmDelete}>Yes</button>
            <button className="cancel-delete" onClick={cancelDelete}>No</button>
          </div>
        </div>
      )}

      {isModalOpen && selectedBlog && (
        <div className="modal" onClick={(e) => e.target.className === 'modal' && closeModal()}>
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>×</span>
            <h2>{selectedBlog.title}</h2>
            <p><strong>Published:</strong> {new Date(selectedBlog.published_date).toLocaleDateString()}</p>
            <img src={selectedBlog.hero_image} alt="Hero" className="modal-hero-image" />
            <div dangerouslySetInnerHTML={{ __html: selectedBlog.content }} />
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;