import React from 'react';
import '../css/FeaturesContainer.css';
import VeeLogo from '../img/UpdatedVee.png';

function FeaturesContainer() {
  return (
    <div className="features-container">
      <h2>Why choose us?</h2>
      <div className="features-grid">
        <div className="feature">
          <img className="feature-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>Seamless</h3>
          <p>Easy to use.  Get work done with just a conversation!</p>
        </div>
        <div className="feature">
          <img className="feature-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>Integrations</h3>
          <p>Plug into your existing systems easily.</p>
        </div>
        <div className="feature">
          <img className="feature-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>Security</h3>
          <p>We abide by the highest compliance standards and guidelines and are committed to transparency.</p>
        </div>
        <div className="feature">
          <img className="feature-gear" src={VeeLogo} alt="Feature Gear" />
          <h3>Popular Service</h3>
          <p>
  Check out one of our products that solves key hiring challenges - with a technology that scales to a diverse range of workforce needs. <a href="/product" className="product-link">Take a look</a>
</p>
        </div>
      </div>
    </div>
  );
}

export default FeaturesContainer;
