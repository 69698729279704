// import React from 'react';
// import '../css/VideoContainer.css';
// import Planet from '../video/connecting_planet.mp4';

// function VideoContainer() {
//   return (
//     <div className="video-container">
//       <video id="trim-video" width="100%" autoPlay loop muted playsInline>
//         <source src={Planet} type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>
//       <div className="video-overlay"></div>
      
//       <div className="overlay-content">
//         {/* <h6>Your <u>People-First</u> HR Automation Platform -<br /></h6>
//         <p>Easily deploy your assistant helpers powered by AI to hire and more!</p> */}
//         <h6>Voice AI for Hiring & More!</h6>
//         {/* Optional buttons can be added here */}
//       </div>
//     </div>
//   );
// }

// export default VideoContainer;

import React from 'react';
import '../css/VideoContainer.css';
import { SparklesCore } from '../../src/components/SparklesCore'; 
import { motion } from 'framer-motion';
import { FloatingPaper } from '../../src/components/FloatingPaper';
import VVFloater from '../img/VVFloater.png'; 

function VideoContainer() {
  return (
    <div className="video-container">
      {/* Sparkles background */}
      <div className="sparkles-container">
        <SparklesCore
          id="tsparticles-video"
          background="transparent"
          minSize={0.6}
          maxSize={1.4}
          particleDensity={100}
          className="w-full h-full"
          particleColor="#FFFFFF"
        />
        <FloatingPaper count={10} />
      </div>

      {/* Comment out the video and its overlay */}
      {/*
      <video id="trim-video" width="100%" autoPlay loop muted playsInline>
        <source src={Planet} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="video-overlay"></div>
      */}

      {/* Enhanced overlay content */}
      <div className="overlay-content">
      <motion.div
          className="bouncing-logo"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <img src={VVFloater} alt="VVFloater Logo" className="logo-image" />
        </motion.div>
        <motion.h6
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="overlay-title"
        >
          Conversations That Work With <span className="title-gradient">AI Power</span>
        </motion.h6>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="overlay-text"
        >
          Let us do the heavy lifting!
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="overlay-buttons"
        >
          {/* <button className="btn-primary">Upload Paper</button>
          <button className="btn-outline">See Examples</button> */}
        </motion.div>
      </div>
    </div>
  );
}

export default VideoContainer;
