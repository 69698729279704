// // src/api/axios.js

// import axios from 'axios';
// import store from '../redux/store'; 
// import { logoutRequested } from '../redux/actions/authActions';


// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_FETCH_URL || 'http://localhost:5000',
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem('token');
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

// axiosInstance.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response && error.response.status === 401) {
//       // Dispatch an action to trigger a logout
//       console.log('401 detected, dispatching logoutRequested');
//       store.dispatch(logoutRequested());
//     }
//     return Promise.reject(error);
//   }
// );
// export default axiosInstance;

// src/api/axios.js
import axios from 'axios';
import store from '../redux/store';
// import { logoutRequested } from '../redux/actions/authActions';
import { logoutRequested } from '../redux/reducers/authReducer';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_FETCH_URL || 'http://localhost:5000',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Remove token interceptor since token is in HTTP-only cookie
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem('token');
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      console.log('401 detected, dispatching logoutRequested');
      store.dispatch(logoutRequested());
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
