// import React from 'react';
// import '../css/ContentContainer.css'; 
// import Matilda from '../audio/ElevenLabs_2024-04-23T18_15_06_Matilda.mp3';
// import Chris from '../audio/ElevenLabs_2024-04-23T18_52_09_Chris_pre_s50_sb75_t2.mp3';

// function ContentContainer() {
//   return (
//     <div className="content-container">
//       <div className="text-content">
//         <h6 className="text-content-heading">What We Do</h6>
//         <p>We streamline the employment process by automating tasks that are often time-consuming and subjective.</p>
//         <p>We can help you hire in dozens of languages too!</p>
//       </div>
//       <div className="image-content">
//         <p>Candidate Screening/Interview Demo Clip:</p>
//         <audio controls>
//           <source src={Matilda} type="audio/mpeg" />
//           Your browser does not support the audio element.
//         </audio>
//         <p>Walk-In Management (Employment Application) Demo Clip:</p>
//         <audio controls>
//           <source src={Chris} type="audio/mpeg" />
//           Your browser does not support the audio element.
//         </audio>
//       </div>
//     </div>
//   );
// }

// export default ContentContainer;


import React from 'react';
import '../css/ContentContainer.css';
import Matilda from '../audio/ElevenLabs_2024-04-23T18_15_06_Matilda.mp3';
import Chris from '../audio/ElevenLabs_2024-04-23T18_52_09_Chris_pre_s50_sb75_t2.mp3';
import Siri from '../components/Siri';
import Audio from '../video/Soundwaves.mp4';

function ContentContainer() {
  return (
    <div className="content-container">
      <div className="text-content">
        <h6 className="text-content-heading">What We Do</h6>
        <p>VettedVoice is passionate about providing successful outcomes for businesses. Our technology is intended to make work faster, better and more seamless. With VettedVoice you can focus on work that matters for hiring and more!</p>
        <p>See a glimpse below - explore what's possible.</p>
      </div>
      <div className="audio-content">
        <div className="demo-sec">
        <p>Candidate Screening/Interview Demo Clip:</p>
        <Siri theme="ios9" audioSrc={Matilda} />
        <p>Employment Application Demo Clip:</p>
        <Siri theme="ios9" audioSrc={Chris} />
        </div>
        <div className="aqua-90">
        <video
            autoPlay
            loop
            playsInline
            muted // Ensures autoplay works in most browsers
            className="layout_card-small-video" // New class for specific video styling
          >
            <source src={Audio} type="video/mp4" />
            Your browser does not support the video element.
          </video>
        </div>
      </div>
    </div>
  );
}

export default ContentContainer;
